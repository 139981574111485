<template>
  <div class="shop-list p20 bsbb">
    <div class="title df aic jcc mb20">
      <p class="fz36">附近商家</p>
    </div>
    <!-- <van-search
      v-model="value"
      background="#f8f8f8"
      right-icon="search"
      placeholder="请输入搜索关键词"
    /> -->
    <div class="list df fdc aic mt40">
      <div class="item df aic font-size-24" @click="goShop(v.id)" v-for="(v, i) in shopList" :key="i">
        <img class="mr40" :src="v.store_environment" />
        <div class="df fdc">
          <p class="font-size-30 fw5 mb20">{{ v.store_name }}</p>
          <p class="mb20" style="
                      width: 240px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    ">
            {{ v.store_address }}
          </p>
          <!-- <div class="df aic jcsb">
            <p>合伙人数</p>
            <p>{{ v.count }}人</p>
          </div> -->
        </div>
      </div>
      <!-- <div class="item df aic font-size-24">
        <img class="mr40" src="../../assets/images/xg/list1.png" />
        <div class="df fdc">
          <p class="font-size-30 fw5 mb20">水娃店铺A</p>
          <p class="mb20">成都市青羊区文殊院时代印象</p>
          <div class="df aic jcsb">
            <p>合伙人数</p>
            <p>30人</p>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { getShopListMsgReq, getShopList } from "@/utils/api.js";
export default {
  data() {
    return {
      value: "",
      shopList: [],
      shopId: "",
    };
  },
  methods: {
    goShop(n) {
      this.$router.push({
        name: "shopCarGoods",
        query: {
          id: n,
        },
      });
    },
    getData() {
      getShopListMsgReq({ page: 1, page_size: 20 }).then((res) => {
        if (res.code === 200) {
          this.shopList = res.data.list;
          console.log(res.data.list);
        }
      });
    },
    // get() {
    //   getShopList({ page: 1, page_size: 10, state: 10 }).then((res) => {
    //     console.log(res);
    //   });
    // },
  },
  created() {
    this.getData();
    // this.get();
  },
};
</script>

<style lang="scss" scoped>
.shop-list {
  .van-search {
    height: 80px;
    border: 1px solid #ddd;
    border-radius: 10px;
  }

  .list {
    width: 710px;
    margin: 40px auto;

    .item {
      width: 690px;
      background: #fff;
      border-radius: 20px;
      margin-top: 40px;

      img {
        width: 150px;
        height: 150px;
      }
    }
  }
}
</style>